import React from 'react';
import SeoComponent from 'components/seo';
import { useStrapiFederalSponsorData } from 'hooks';
import { Hero, Breadcrumb } from "components/ui";
import { getSrc } from 'gatsby-plugin-image';
import Layout from 'components/layout';

const isBrowser = typeof window !== "undefined"

const FederalSponsors=({ location })=>{
    let thisLocationPathname = ''
    if (isBrowser) {
        thisLocationPathname = window.location.pathname;
    }

    const { strapiFederalSponsor } = useStrapiFederalSponsorData();
    const title = strapiFederalSponsor?.title;
    const seo = {
      metaTitle: strapiFederalSponsor?.title,
      metaDescription: strapiFederalSponsor?.description,
      metaKeywords: strapiFederalSponsor?.keywords
    }

    return (
        <Layout location={location} title={title}>
          <SeoComponent title={seo.metaTitle} description={seo.metaDescription} keywords={seo.metaKeywords} />
            <Hero
              img={getSrc(strapiFederalSponsor?.hero_image?.background_image?.localFile)}
              color="#f5f5f5"
              isFixedHeight={false}
              >
                <Breadcrumb pathname={thisLocationPathname} />
                <h1 className="usa-hero__heading" aria-label={`Hero: ${title}`} name="main-content" id="main-content">{title}</h1>
            </Hero>
            <div className="padding-y-5">
              <div className="grid-container">
                <div className="grid-row grid-gap">
                  {strapiFederalSponsor?.body &&
                  <div className="desktop:grid-col-7 tablet:grid-col-12"
                    dangerouslySetInnerHTML={{
                    __html: strapiFederalSponsor?.body?.data?.childMarkdownRemark?.html,
                    }}
                  />
                  }
                </div>
              </div>
            </div>
        </Layout>
    )
}

export default FederalSponsors;
